<template>
  <div class="container">
    <div class="nav">
      <div class="nav_title">付款码</div>
      <div class="nav_left_btn" @click="back">
        <img :src="cancel" alt width="15" height="25" />
      </div>
    </div>
    <div class="content">
      <div class="count-down">
        <div v-show="time === 0" class="mask" @click="updateCode" />
        <img v-show="time === 0" :src="refresh" alt class="refresh" @click="updateCode" />
        <img :src="generateQR" alt />
      </div>
      <div class="tips">提示：二维码有效期为{{ time }}s，过期后请点击二维码进行刷新</div>
    </div>
  </div>
</template>
<script>
import QRCode from "qrcode";
import cancel from "@/assets/cancel.png";
import refresh from "@/assets/refresh.png";
import { Toast } from "vant";
import { getPayCode } from "@/api/api";

export default {
  data() {
    return {
      generateQR: "",
      cancel,
      refresh,
      time: "",
      interval: "",
    };
  },
  created() {
    const { fetchPayCode } = this;
    fetchPayCode();
  },
  methods: {
    async fetchPayCode() {
      const { userId } = this.$store.getters;
      const res = await getPayCode({ id: userId });
      const { code, data, message } = res;
      if (code === 0) {
        const generateQR = await QRCode.toDataURL(data);
        console.log(generateQR);
        this.generateQR = generateQR;
        this.time = JSON.parse(res.data).expireIn;
        await this.countDown();
      } else {
        Toast.fail({
          message,
          duration: 1000,
        });
      }
    },
    async updateCode() {
      if (this.time >= 0) {
        clearInterval(this.interval);
      }
      await this.fetchPayCode();
    },
    back() {
      this.$router.back();
    },
    countDown() {
      this.interval = setInterval(() => {
        this.time--;
        if (this.time <= 0) {
          clearInterval(this.interval);
        }
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  & > .nav {
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    text-align: center;
    height: 3rem;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 3rem;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 0.9rem 0.5rem 0.9rem 0.7rem;
      border-color: transparent;
      background-size: 1.2rem 2rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 0.3rem 0 0.8rem;
      top: 9px;
      left: 0px;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }

    & > .nav_left_btn:active {
      opacity: 0.5;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }

  & > .content {
    padding-top: 8rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    & > .count-down {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 90%;
      img {
        width: 100%;
      }
      & > .mask {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: #fff;
        opacity: 0.7;
      }

      & > .refresh {
        width: 4rem;
        height: 4rem;
        position: absolute;
      }
    }

    & > .tips {
      margin-top: 2.5rem;
      width: 15rem;
      font-size: 1.1rem;
    }
  }
}
</style>
